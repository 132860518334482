import React, { useEffect, useState } from 'react';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
import { Button } from '@vkontakte/vkui';
import { useIntl } from 'react-intl';
import { useUnit } from 'effector-react';
import Typograf from '@common/components/common/Typograf';
import SubscriptionProcessing from '@common/components/pages/Main/SubscriptionProcessing/component';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { showManagementModal } from '@common/entities/subscriptions/lib/modals/show-management-modal';
import PagePlaceholder from '@common/components/common/PagePlaceholder';
import { ACTIVE_SUBSCRIPTION_STATUS_LIST } from '@common/entities/subscriptions/lib/constants';
import { offerHasTag } from '@common/entities/offers';
import { STUDENTS_TAG_OFFER } from '@common/entities/offers/lib/consts';
import { StartStudentFlowModel } from '@common/features/start-student-flow';
import styles from './styles.module.scss';
import { OfferItem, Subscription } from './ui';
import { messages } from './messages';
import { applyOffer } from '@common/components/Root/actions/applyOffer';
import { StudentModel } from '@common/entities/student';
import { ROUTE_PROFILE } from '@apps/common/router';
import { appEvents } from '@apps/common/utils/apps-events';
import Router from 'next/router';
import { getDataPulling } from '@apps/common/components/Root/actions';
const Tariffs = (props) => {
    const { offers } = props;
    const [studentStatus] = useUnit([StudentModel.$status]);
    const [subscriptionsStatus, subscription] = useUnit([
        SubscriptionsModel.$status,
        SubscriptionsModel.$subscriptionExcludeWaitingStatus,
    ]);
    const [currentCorrelationId, setCurrentCorrelationId] = useState(null);
    const intl = useIntl();
    useEffect(() => {
        const unsubscribeSubscriptionActivatedEvent = appEvents.addEventListener('subscriptionActivated', () => {
            getDataPulling({ all: true });
            Router.push(ROUTE_PROFILE);
        });
        return () => {
            unsubscribeSubscriptionActivatedEvent();
        };
    }, []);
    const showStopModal = ({ url, title, info, distributor }) => showManagementModal({
        title: info,
        buttonText: title,
        distributorName: distributor?.name ?? null,
        url,
    });
    if (offers.length === 0) {
        return (<PagePlaceholder title={intl.formatMessage(messages.emptyTitle)} description={intl.formatMessage(messages.emptyDescription)}/>);
    }
    let firstBlock = null;
    if (subscriptionsStatus === 'FORMED') {
        firstBlock = <SubscriptionProcessing />;
    }
    else {
        if (subscription) {
            firstBlock = <Subscription subscription={subscription}/>;
        }
    }
    const applyOfferCallback = (params) => async () => {
        try {
            setCurrentCorrelationId(params.correlationId);
            const offer = offers.find((item) => item.correlationId === params.correlationId);
            const isStudentOffer = offer ? offerHasTag(offer, STUDENTS_TAG_OFFER) : false;
            const widget = offer?.widgets.offer || offer?.widgets.trial;
            if (subscription &&
                ACTIVE_SUBSCRIPTION_STATUS_LIST.includes(subscription?.status) &&
                subscription?.management &&
                subscription.management.distributor?.external) {
                showStopModal(subscription.management);
                setCurrentCorrelationId(null);
                return;
            }
            if (isStudentOffer && offer) {
                StartStudentFlowModel.startFlow({ callbackAfterFinishedFlow: () => setCurrentCorrelationId(null) });
                return;
            }
            await applyOffer({ ...params, offerName: widget?.badge?.title ?? '' });
            setCurrentCorrelationId(null);
        }
        catch {
            setCurrentCorrelationId(null);
        }
    };
    const getFieldAction = (index) => (button, correlationId) => {
        if (!button) {
            return null;
        }
        if (button.type === 'student_form' && studentStatus === 'WAIT') {
            return (<span className={styles.underConsideration}>
            <Typograf>{intl.formatMessage(messages.studentStatusWaitTitle)}</Typograf>
          </span>);
        }
        const defaultButton = {
            name: button.name,
            type: button.type,
            params: button.params,
        };
        return (<Button {...createAnalyticClickAttribute(analyticsDictionary.otherOffersButtonClick(button.metrics, index))} onClick={applyOfferCallback({
                correlationId,
                button: defaultButton,
            })} loading={currentCorrelationId === correlationId} disabled={Boolean(currentCorrelationId)}>
          {button.name}
        </Button>);
    };
    return (<>
      {firstBlock}

      {offers.map((fullOffer, index) => (<OfferItem key={fullOffer.correlationId} fullOffer={fullOffer} index={index} getFieldAction={getFieldAction(index)}/>))}
    </>);
};
export default Tariffs;
