import React from 'react';
import { ButtonGroup } from '@vkontakte/vkui';
import { useIntl } from 'react-intl';
import { createAnalyticClickAttribute } from '@analytics';
import ModalButton from '@common/components/modals/ModalButton';
import { studentAnalytics } from '@common/entities/student/lib/analytics';
import { messages } from '../messages';
export const Action = (props) => {
    const { url, buttonText } = props;
    const intl = useIntl();
    const text = buttonText || intl.formatMessage(messages.buttonText);
    const onClick = () => {
        if (url) {
            (window.parent.location || window.location).href = url;
        }
    };
    return (<ButtonGroup stretched={true} mode={'vertical'}>
      <ModalButton size={'s'} mode='primary' onClick={onClick} {...createAnalyticClickAttribute(studentAnalytics.confirm.externalSubscriptionActiveClick)}>
        {text}
      </ModalButton>
    </ButtonGroup>);
};
