import React from 'react';
import { analyticsDictionary } from '@analytics';
import { modalManagerModel } from '@common/services/modal-manager';
const payloadToManagerModal = async (props) => {
    const Header = await import('./ui/header').then((item) => item.Header);
    const Footer = await import('./ui/footer').then((item) => item.Footer);
    const Content = await import('./ui/content').then((item) => item.Content);
    if (!Header || !Footer || !Content) {
        return null;
    }
    return {
        analyticShowAttribute: analyticsDictionary.modalPaymethodCancelReason,
        header: <Header />,
        children: <Content />,
        footer: <Footer successHandler={props?.successHandler}/>,
    };
};
export const showUnlinkCardReasonModal = async (props) => {
    const payload = await payloadToManagerModal(props);
    if (!payload) {
        return;
    }
    return modalManagerModel.showModalPageWithFixedFooter(payload);
};
export default showUnlinkCardReasonModal;
