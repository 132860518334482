import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { ROUTES } from '@website/shared/lib/routes';
import { useRouter } from 'next/router';
const ActiveSubscriptionGuard = ({ children }) => {
    const userHasSubscriptionWithPermissionGoToLk = useUnit(SubscriptionsModel.$userHasSubscriptionWithPermissionGoToLk);
    const subscriptionsState = useUnit(SubscriptionsModel.$subscriptionsState);
    const status = useUnit(SubscriptionsModel.$status);
    const content = userHasSubscriptionWithPermissionGoToLk ? children : null;
    const isFinishedSubscriptionsState = subscriptionsState === 'success' || subscriptionsState === 'error';
    const router = useRouter();
    useEffect(() => {
        if (!userHasSubscriptionWithPermissionGoToLk && isFinishedSubscriptionsState && status !== null) {
            void router.push(ROUTES.root);
        }
    }, [userHasSubscriptionWithPermissionGoToLk, subscriptionsState, status]);
    return <>{content}</>;
};
export default ActiveSubscriptionGuard;
