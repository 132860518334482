import React from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
import { ModalPageContent } from '@common/shared/ui/modals';
export const preloadShowSubscriptionInQueuePayload = async () => {
    const [Content, Header] = await Promise.all([import('./ui/content'), import('./ui/header')]).then((components) => components.map((component) => component.default));
    if (!Content || !Header) {
        return null;
    }
    return {
        analyticShowAttribute: 'modal_subscription_chain',
        header: <Header />,
        children: (<ModalPageContent>
        <Content />
      </ModalPageContent>),
    };
};
export const showSubscriptionInQueueModal = async () => {
    const payload = await preloadShowSubscriptionInQueuePayload();
    if (!payload) {
        return;
    }
    modalManagerModel.showModalPage(payload);
};
