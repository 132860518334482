import { createEffect, createEvent, createStore, sample } from 'effector';
import { getPaymentMethod } from '@common/data-layer/payment-method/model/payment-method';
import { modalManagerModel } from '@common/services/modal-manager';
import { paymentApi } from '@common/entities/payment-method/api';
export const $unlinkPaymentMethodState = createStore('idle');
export const changeUnlinkPaymentMethodState = createEvent();
$unlinkPaymentMethodState.on(changeUnlinkPaymentMethodState, (_state, payload) => payload);
export const unlinkPaymentMethod = createEvent();
export const unlinkPaymentMethodFx = createEffect(async (params) => {
    const { successHandler, errorHandler, reasons } = params;
    changeUnlinkPaymentMethodState('process');
    try {
        const isUnlinkSuccessful = (await paymentApi.unlinkPaymentsMethod({ reasons })).data.successful;
        modalManagerModel.closeModal();
        if (!isUnlinkSuccessful) {
            changeUnlinkPaymentMethodState('error');
            errorHandler();
            return;
        }
        changeUnlinkPaymentMethodState('idle');
        getPaymentMethod();
        successHandler();
    }
    catch {
        changeUnlinkPaymentMethodState('error');
        errorHandler();
    }
});
sample({ clock: unlinkPaymentMethod, fn: (clockData) => clockData, target: unlinkPaymentMethodFx });
