import vkBridge from '@vkontakte/vk-bridge';
import { analyticsDictionary, callAnalyticClick, callAnalyticShowDirect } from '@common/utils/Analytics';
import apiVkBridge from '@common/utils/ApiVkBridge';
import { getCheckoutData } from '@common/entities/checkout-subscription';
import getPaymentMethodPaymentId from '@common/helpers/getPaymentMethodPaymentId';
import { getDataPulling } from '@common/components/Root/actions/getDataPulling';
import { IS_MINIAPP } from '@common/consts';
import { modalManagerTools } from '@common/services/modal-manager';
import { showCheckoutModal } from '@common/entities/checkout';
const showNativeWindow = async (orderUuid) => {
    if (!IS_MINIAPP) {
        return false;
    }
    const { platform } = await apiVkBridge.getClientVersion();
    if (platform !== 'android') {
        return false;
    }
    const checkoutData = await getCheckoutData({ orderUuid, paymentView: 'native_window' });
    if (typeof checkoutData.payment.payload === 'string') {
        return false;
    }
    callAnalyticShowDirect(analyticsDictionary.modalCheckoutFormNativeSubscriptionShow);
    await vkBridge
        .send('VKWebAppVKPayCheckout', checkoutData.payment.payload)
        .then(async () => {
        await getDataPulling({ all: true });
        modalManagerTools.modalTemplates.showMessageModal({
            analyticShowAttribute: 'modal_checkout_form_native_subscription_success',
            mode: 'success',
            header: 'Способ оплаты подтвержден!',
            subheader: 'Теперь ваша подписка \n' + 'будет обновляться автоматически',
        });
    })
        .catch((e) => {
        if (e?.error_data?.error_code === 4) {
            callAnalyticClick(analyticsDictionary.modalCheckoutFormNativeSubscriptionButtonCancelClick);
            return;
        }
        modalManagerTools.modalTemplates.showMessageModal({
            analyticShowAttribute: 'modal_checkout_form_native_subscription_error',
            mode: 'error',
            header: 'Упс',
            subheader: 'Что-то пошло не так',
        });
    });
    return true;
};
export const linkPaymentMethod = async (type, fn = showCheckoutModal) => {
    const result = await getPaymentMethodPaymentId();
    const orderUuid = result.data.order.uuid;
    const accessNativeWindow = await showNativeWindow(orderUuid);
    if (accessNativeWindow) {
        return;
    }
    await fn({ paymentId: orderUuid, type, paymentType: 'card_link', paymentTypeParams: {} });
};
