import styles from './theme-q3-2024.module.scss';
const MAP = {
    violet: styles.violet,
    pink: styles.pink,
    magenta: styles.magenta,
    purple: styles.purple,
    orange: styles.orange,
    blue: styles.blue,
};
export const getCardThemeQ32024 = (theme) => {
    if (!theme) {
        return;
    }
    const value = MAP[theme];
    if (!value) {
        return;
    }
    return value;
};
