import React from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
import { studentAnalytics } from '@common/entities/student/lib/analytics';
import { Action, Header, Subheader } from './ui';
export const showManagementModal = (params) => {
    const { title, distributorName, buttonText, url } = params;
    modalManagerModel.showModal({
        analyticShowAttribute: studentAnalytics.confirm.externalSubscriptionActiveShow,
        mode: 'info',
        header: <Header title={title}/>,
        subheader: <Subheader distributorName={distributorName}/>,
        actions: (<Action buttonText={buttonText} url={url}/>),
    });
};
