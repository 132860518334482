import { createEffect } from 'effector';
import { userApi } from './api';
import apiCommon from '@common/utils/ApiCommon';
const getProfileInfoFx = createEffect(async () => {
    const { data } = await userApi.getProfileInfo();
    if (!data.success) {
        return Promise.reject(data.error);
    }
    return data.data;
});
const getPausedRecurrentsStateFx = createEffect(async () => {
    const { data: result } = await apiCommon.getPauseSubscriptionRecurrentStatus();
    if (!result.successful) {
        return result.errors;
    }
    return result.data;
});
export const userEffects = { getProfileInfoFx, getPausedRecurrentsStateFx };
