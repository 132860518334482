import { IS_MINIAPP } from '@common/consts';
import { showSubscriptionCancelReasonModal } from '@common/entities/subscriptions/lib/modals';
import { $offersSuccess, getOffersFx } from '@common/features/cancel-subscription/model/offers';
import { api } from '../api';
import { showWinbackCancelSubscription } from '../lib/modals';
import apiVkBridge from '@apps/common/utils/ApiVkBridge';
const showWinbackCancelSubscriptionHandler = async (params) => {
    const { subscriptionId } = params;
    const platform = IS_MINIAPP ? 'miniapp' : 'website';
    await getOffersFx({ keywords: [`winback_${platform}`] });
    if (!$offersSuccess.getState()?.correlationId) {
        showSubscriptionCancelReasonModal({ subscriptionId });
        return;
    }
    showWinbackCancelSubscription(subscriptionId);
};
export const winbackFlow = async (params) => {
    const { subscriptionId } = params;
    const isMiniappIos = !!(IS_MINIAPP && (await apiVkBridge.getClientVersion())?.platform === 'ios');
    const isWinbackAvailable = await api
        .getWinbackCheck()
        .then((res) => {
        if (!res.successful) {
            throw new Error('');
        }
        return res.data.available;
    })
        .catch(() => {
        return false;
    });
    if (!isWinbackAvailable || isMiniappIos) {
        showSubscriptionCancelReasonModal({ subscriptionId });
        return;
    }
    await showWinbackCancelSubscriptionHandler(params);
};
