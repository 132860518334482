import apiCommon from '@common/utils/ApiCommon';
import UnlinkPaymentMethodParamsSchema from 'common/entities/payment-method/api/schemas/UnlinkPaymentMethodParamsSchema.json';
const UnlinkPaymentMethodResponseSchema = () => import('common/entities/payment-method/api/schemas/UnlinkPaymentMethodResponseSchema.json');
const LinkPaymentMethodResponseSchema = () => import('common/entities/payment-method/api/schemas/LinkPaymentMethodResponseSchema.json');
const GetPaymentsHistoryResponseSchema = () => import('common/entities/payment-method/api/schemas/GetPaymentsHistoryResponseSchema.json');
const getPaymentsHistory = () => {
    return apiCommon.makeGetRequest('/api/front/user/payments/list', undefined, undefined, GetPaymentsHistoryResponseSchema);
};
const linkPaymentMethod = () => apiCommon.makePostRequest('api/front/user/payments/card/link', undefined, undefined, LinkPaymentMethodResponseSchema);
const unlinkPaymentsMethod = (params) => apiCommon.makePostRequest('api/front/user/payments/unlink-pay-method', params, UnlinkPaymentMethodParamsSchema, UnlinkPaymentMethodResponseSchema);
export const paymentApi = {
    getPaymentsHistory,
    linkPaymentMethod,
    unlinkPaymentsMethod,
};
