import { createEffect, createStore } from 'effector';
import { checkoutApi } from '@common/entities/checkout';
const checkoutSettingsDefaultValue = {
    version: 'v3',
};
const $checkoutSettings = createStore(checkoutSettingsDefaultValue);
const getCheckoutSettingsFx = createEffect(async (params) => {
    const { uuid } = params;
    try {
        const { data } = (await checkoutApi.getCheckoutSettings(uuid)).data;
        return data.checkout;
    }
    catch {
        return checkoutSettingsDefaultValue;
    }
});
$checkoutSettings.on(getCheckoutSettingsFx.doneData, (_, payload) => ({ ...payload }));
export const checkoutSettingsModel = {
    getCheckoutSettingsFx,
    $checkoutSettings,
    $checkoutSettingsLoading: getCheckoutSettingsFx.pending,
};
