import { createEffect, sample } from 'effector';
import apiCommon from '@common/utils/ApiCommon';
import waitForResult from '@common/helpers/waitForResult';
import { showSubscriptionRecoveryErrorModal, showSubscriptionRecoverySuccessModal, } from '@common/entities/subscriptions/lib/modals';
const DEFAULT_ERROR_CALLBACK = createEffect((error) => {
    showSubscriptionRecoveryErrorModal(error);
});
const DEFAULT_SUCCESS_CALLBACK = createEffect(() => {
    showSubscriptionRecoverySuccessModal();
});
const successCallbackFx = createEffect();
const errorCallbackFx = createEffect();
const stopPauseRecurrentFx = createEffect(async () => {
    const { data } = await apiCommon.resumePauseSubscriptionRecurrent();
    if (!data.successful) {
        return Promise.reject(data.errors);
    }
});
const forceStopPauseRecurrentFx = createEffect(async () => {
    const successful = (await apiCommon.suspendedSubscriptionRenew()).data.successful;
    if (!successful) {
        return Promise.reject();
    }
    return new Promise((resolve, reject) => {
        waitForResult(apiCommon.suspendedSubscriptionCheckRenewStatus, {
            params: [],
            handleResponse: (result) => {
                if (result.data.successful) {
                    const status = result.data.data.state;
                    if (status === 'success') {
                        resolve(result);
                        return false;
                    }
                    if (status === 'fail' || status === 'lock') {
                        reject();
                        return false;
                    }
                }
                return true;
            },
            handleError: (error) => {
                reject(error);
                return false;
            },
        });
    });
});
const useManualRecurrentFx = createEffect(async (subscription) => {
    if (subscription && subscription.status === 'active') {
        return await stopPauseRecurrentFx();
    }
    return await forceStopPauseRecurrentFx();
});
const manualStoppedPauseRecurrent = createEffect(async ({ subscription, successCallback, errorCallback }) => {
    successCallback && successCallbackFx.use(successCallback);
    errorCallback && errorCallbackFx.use(errorCallback);
    await useManualRecurrentFx(subscription);
});
const $inProgress = useManualRecurrentFx.pending;
sample({ clock: manualStoppedPauseRecurrent.done, target: [DEFAULT_SUCCESS_CALLBACK, successCallbackFx] });
sample({ clock: manualStoppedPauseRecurrent.failData, target: [DEFAULT_ERROR_CALLBACK, errorCallbackFx] });
export const manualStoppedPauseRecurrentModel = {
    $inProgress,
    manualStoppedPauseRecurrent,
};
