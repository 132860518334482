import vkBridge from '@vkontakte/vk-bridge';
import { analyticsDictionary, callAnalyticClick, callAnalyticShowDirect } from '@common/utils/Analytics';
import paymentMethod from '@common/utils/PaymentMethod';
import { getDataPulling } from '@common/components/Root/actions/getDataPulling';
import tryToGetErrorsMessages from '@common/helpers/tryToGetErrorsMessages';
import { showCountryNotSupportedModal } from '@common/features/apply-offer';
import { groupSubscriptionFlowModel } from '@common/features/group-subscription-flow';
import { studentFlowModel } from '@common/features/start-student-flow/model';
import { showStudentConfirmStatusPlatformNotSupportedModal } from '@common/entities/student';
import { modalManagerTools } from '@common/services/modal-manager';
import { showCheckoutModal } from '@common/entities/checkout';
import { userFlowManagerModel } from '@common/entities/user-flow-manager';
import { conditionManagerModel } from '@common/entities/conditions-manager/model';
import { showSuccessfulPaymentModal } from '@apps/common/data-layer/payment-method';
import { commonShowSuccessfulPaymentModalParams } from '@apps/common/entities/checkout/hooks';
const onShowSuccessfulPaymentModal = (params) => {
    showSuccessfulPaymentModal(params);
};
const showDefaultError = () => {
    userFlowManagerModel.processingBackendErrors({
        title: 'Упс',
        description: 'Попробуйте позже',
    });
};
export const applyOffer = async (args) => {
    const { button } = args;
    switch (button.type) {
        case 'redirect': {
            let url = null;
            const { params } = button;
            if (typeof params && !Array.isArray(params)) {
                url = params?.url;
            }
            if (typeof url === 'string') {
                userFlowManagerModel.redirect(url);
                return;
            }
            showDefaultError();
            return;
        }
        default: {
            const result = paymentMethod
                .handleButtonType(args)
                .then(async (result) => {
                let showModal = false;
                if (result.successful) {
                    switch (result.type) {
                        case 'native_window': {
                            if (typeof result.payload === 'string') {
                                return Promise.reject();
                            }
                            const nativeWindowLogic = async () => {
                                callAnalyticShowDirect(analyticsDictionary.modalCheckoutFormNativeSubscriptionShow);
                                await vkBridge
                                    .send('VKWebAppVKPayCheckout', result.payload)
                                    .then(async () => {
                                    await getDataPulling({ all: true });
                                    onShowSuccessfulPaymentModal({
                                        ...commonShowSuccessfulPaymentModalParams({
                                            type: 'offer',
                                            paymentType: result.paymentType,
                                            paymentTypeParams: result?.paymentTypeParams,
                                        }),
                                        platform: 'android',
                                    });
                                })
                                    .catch((error) => {
                                    if (error?.error_data?.error_code !== 4) {
                                        modalManagerTools.modalTemplates.showMessageModal({
                                            analyticShowAttribute: 'modal_apply_offer_error',
                                            mode: 'error',
                                            header: 'Упс',
                                            subheader: 'Что-то пошло не так',
                                        });
                                    }
                                    else {
                                        callAnalyticClick(analyticsDictionary.modalCheckoutFormNativeSubscriptionButtonCancelClick);
                                    }
                                });
                                showModal = true;
                            };
                            await nativeWindowLogic();
                            showModal = true;
                            break;
                        }
                        case 'purchase': {
                            const purchaseLogic = async () => {
                                if (result.payable) {
                                    if (result.uuid) {
                                        await showCheckoutModal({
                                            paymentId: result.uuid,
                                            paymentType: result?.paymentType || 'subscription_purchase',
                                            paymentTypeParams: result?.paymentTypeParams || {},
                                            type: 'offer',
                                        });
                                    }
                                    else {
                                        return Promise.reject();
                                    }
                                    showModal = true;
                                }
                                else {
                                    await getDataPulling({ all: true });
                                    modalManagerTools.modalTemplates.showMessageModal({
                                        analyticShowAttribute: 'modal_apply_offer_success',
                                        mode: 'success',
                                        header: 'Подписка оформлена',
                                    });
                                    showModal = true;
                                }
                            };
                            await purchaseLogic();
                            showModal = true;
                            break;
                        }
                        case 'condition': {
                            const { conditions, condition } = result;
                            switch (condition.cause) {
                                case 'user_buy_upsell': {
                                    conditionManagerModel.getCondition[condition.cause]({
                                        handler: async () => {
                                            await applyOffer({ ...args, conditions: [...conditions, condition.id] });
                                            return;
                                        },
                                        subscriptionName: condition?.params?.subscription_name || '',
                                    });
                                    return;
                                }
                                case 'user_with_trial_buy_upsell': {
                                    conditionManagerModel.getCondition[condition.cause]({
                                        handler: async () => {
                                            await applyOffer({ ...args, conditions: [...conditions, condition.id] });
                                            return;
                                        },
                                        subscriptionName: condition?.params?.subscription_name || '',
                                    });
                                    return;
                                }
                                case 'community_owner_buy_upsell': {
                                    conditionManagerModel.getCondition[condition.cause]({
                                        handler: async () => {
                                            await applyOffer({ ...args, conditions: [...conditions, condition.id] });
                                            return;
                                        },
                                    });
                                    return;
                                }
                                case 'user_with_free_sub_buy_upsell': {
                                    conditionManagerModel.getCondition[condition.cause]({
                                        handler: async () => {
                                            await applyOffer({ ...args, conditions: [...conditions, condition.id] });
                                            return;
                                        },
                                        subscriptionName: condition?.params?.subscription_name || '',
                                    });
                                    return;
                                }
                                case 'user_with_long_sub_buy_upsell': {
                                    conditionManagerModel.getCondition[condition.cause]({
                                        handler: async () => {
                                            await applyOffer({ ...args, conditions: [...conditions, condition.id] });
                                            return;
                                        },
                                        subscriptionName: condition?.params?.subscription_name || '',
                                    });
                                    return;
                                }
                                case 'user_buy_downsell': {
                                    conditionManagerModel.getCondition[condition.cause]({
                                        handler: async () => {
                                            await applyOffer({ ...args, conditions: [...conditions, condition.id] });
                                            return;
                                        },
                                        subscriptionName: condition?.params?.subscription_name || '',
                                    });
                                    return;
                                }
                                case 'community_owner_buy_downsell': {
                                    conditionManagerModel.getCondition[condition.cause]({
                                        handler: async () => {
                                            await applyOffer({ ...args, conditions: [...conditions, condition.id] });
                                            return;
                                        },
                                    });
                                    return;
                                }
                                case 'user_in_suspend_buy_sub': {
                                    conditionManagerModel.getCondition[condition.cause]({
                                        handler: async () => {
                                            await applyOffer({ ...args, conditions: [...conditions, condition.id] });
                                            return;
                                        },
                                        subscriptionName: condition?.params?.subscription_name || '',
                                    });
                                    return;
                                }
                                case 'community_owner_in_suspend_buy_sub': {
                                    conditionManagerModel.getCondition[condition.cause]({
                                        handler: async () => {
                                            await applyOffer({ ...args, conditions: [...conditions, condition.id] });
                                            return;
                                        },
                                        subscriptionName: condition?.params?.subscription_name || '',
                                    });
                                    return;
                                }
                                case 'community_member_in_suspend_buy_sub': {
                                    conditionManagerModel.getCondition[condition.cause]({
                                        handler: async () => {
                                            await applyOffer({ ...args, conditions: [...conditions, condition.id] });
                                            return;
                                        },
                                        subscriptionName: condition?.params?.subscription_name || '',
                                    });
                                    return;
                                }
                                case 'community_member_buy_sub': {
                                    conditionManagerModel.getCondition[condition.cause]({
                                        handler: async () => {
                                            await applyOffer({ ...args, conditions: [...conditions, condition.id] });
                                            return;
                                        },
                                        subscriptionName: condition?.params?.subscription_name || '',
                                    });
                                    return;
                                }
                                case 'user_buy_community': {
                                    conditionManagerModel.getCondition[condition.cause]({
                                        handler: async () => {
                                            await applyOffer({ ...args, conditions: [...conditions, condition.id] });
                                            return;
                                        },
                                        subscriptionName: condition?.params?.subscription_name || '',
                                    });
                                    return;
                                }
                            }
                            break;
                        }
                        case 'open_deep_link': {
                            const link = document.createElement('a');
                            link.href = result.url;
                            link.click();
                            showModal = true;
                            break;
                        }
                        case 'group_subscription': {
                            await groupSubscriptionFlowModel.start({
                                correlationId: args.correlationId,
                                finishedCallback: args.callbackAfterFinishedFlow,
                                offerName: args.offerName,
                            });
                            showModal = true;
                            break;
                        }
                        case 'student_form': {
                            await new Promise((resolve) => {
                                studentFlowModel.startFlow({
                                    callbackAfterFinishedFlow: () => {
                                        resolve();
                                        args.callbackAfterFinishedFlow && args.callbackAfterFinishedFlow();
                                    },
                                });
                            });
                            showModal = true;
                            break;
                        }
                        case 'platform_not_supported': {
                            showStudentConfirmStatusPlatformNotSupportedModal();
                            showModal = true;
                            break;
                        }
                        case 'country_not_supported': {
                            showCountryNotSupportedModal();
                            showModal = true;
                            break;
                        }
                    }
                }
                else {
                    const errorsMessages = tryToGetErrorsMessages(result.errors, {
                        title: 'Упс',
                        description: 'Попробуйте позже',
                    });
                    userFlowManagerModel.processingBackendErrors(errorsMessages);
                    showModal = true;
                }
                return Promise.resolve({ ...result, showModal });
            })
                .catch((error) => {
                const { title: header, description: subheader } = tryToGetErrorsMessages(error, {
                    title: 'Упс',
                    description: 'Попробуйте позже',
                });
                modalManagerTools.modalTemplates.showMessageModal({
                    analyticShowAttribute: 'modal_apply_offer_error',
                    mode: 'error',
                    header,
                    subheader,
                });
                return Promise.reject({ error: error, showModal: true });
            })
                .finally(() => {
                args.callbackAfterFinishedFlow && args.callbackAfterFinishedFlow();
            });
            return result;
        }
    }
};
