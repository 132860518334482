import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useGate, useUnit } from 'effector-react';
import apiCommon from '@common/utils/ApiCommon';
import { DesktopNavigation } from '@common/shared/ui';
import { AuthGuard } from '@common/entities/auth-state';
import { ActiveSubscriptionGuard, CurrentSubscriptionGuard } from '@common/entities/subscriptions/ui';
import { MainLayout } from '@website/shared/ui/layouts/main-layout';
import { redirectToMainPage } from '@common/application/configuration/interceptors/interceptorsFor401Error';
import { Header } from '@website/widgets/header';
import { buttonsSettingsCreator } from './lib/buttons-settings';
import { PROFILE_TEMPLATE_META_TAGS } from './lib/meta-tags';
import { Header as HeaderContent, ProfileContentLayout } from './ui';
import { ProfileTemplateModel } from './model';
import '@common/application/observers/info-banner-widget-observer';
import '@common/application/observers/subscription-observe';
const ProfileTemplate = (props) => {
    useGate(ProfileTemplateModel.Gate);
    const [navigationBarLoading] = useUnit([ProfileTemplateModel.navigationBarLoading]);
    const unreadNotificationsCount = 0;
    const handler = (pathname) => () => {
        void router.push(pathname);
    };
    const buttonsSettings = buttonsSettingsCreator({ unreadNotificationsCount, handler });
    const { home, faq, other_offers, notifications, paymethod } = buttonsSettings;
    const { children, offPadding, header = (<HeaderContent onGoToProfile={() => void home.handler()} onGoToNotification={() => void notifications.handler()} onGoToFaq={() => void faq.handler()} onGoToTariffs={() => void other_offers.handler()} onGoToPaymentMethod={() => void paymethod.handler()}/>), } = props;
    const router = useRouter();
    const conditionDisable = router.pathname;
    const content = children;
    const sideBar = (<DesktopNavigation conditionDisable={conditionDisable} buttonsSettings={buttonsSettings} loading={navigationBarLoading}/>);
    useEffect(() => {
        const id = apiCommon.setInterceptorsResponse(null, redirectToMainPage);
        return () => {
            apiCommon.deleteInterceptorResponse(id);
        };
    }, []);
    return (<MainLayout metaTags={PROFILE_TEMPLATE_META_TAGS}>
      <AuthGuard>
        <ActiveSubscriptionGuard>
          <CurrentSubscriptionGuard>
            <Header />
            <MainLayout.Content>
              <ProfileContentLayout header={header} content={content} offPadding={offPadding} sideBar={sideBar}/>
            </MainLayout.Content>
          </CurrentSubscriptionGuard>
        </ActiveSubscriptionGuard>
      </AuthGuard>
    </MainLayout>);
};
export default ProfileTemplate;
