import { IS_DEVELOPMENT } from '@common/consts';
import { ANALYTIC_PLATFORM_SOURCE, ANALYTIC_PLATFORM_TYPE } from './environment';
export * from './environment';
export * from './storageKeys';
export * from './functional';
export * from './http-statuses';
export * from './seo';
export * from './consts';
export * from './keywords';
export const VKCOMBO_PROMOCODE_URL = 'https://vkcombo.ru/promocode';
export const VKMUSIC_PROMOCODE_URL = 'https://music.vk.com/promocode';
export const MUSIC_HELP_MINIAPP_URL = 'https://vk.com/app6126832#act=home_music_miniapp';
export const MUSIC_HELP_MINIAPP_ID = 6126832;
export const MUSIC_HELP_MINIAPP_LOCATION = 'act=home_music_miniapp';
export const IN_APP_PAYMENT_DEEP_LINK = 'https://vk.com/popup?act=buy_music_subscription&ref=miniapp';
export const MINIAPP_HASH_PROFILE = 'profile';
export const MINIAPP_HASH_FAQ = 'faq';
export const MINIAPP_HASH_PLANS = 'plans';
export const MINIAPP_HASH_NOTIFICATIONS = 'notifications';
export const MINIAPP_HASH_CHECKOUT = 'checkout';
export const MINIAPP_HASH_PURCHASE = 'p';
export const MINIAPP_HASH_JOIN_GROUP = 'joinGroup';
export const ANALYTIC_VK_PLATFORM = 'X-Vk-Platform';
const ANALYTIC_PLATFORM = `${ANALYTIC_PLATFORM_SOURCE}_${ANALYTIC_PLATFORM_TYPE}`;
export { ANALYTIC_PLATFORM };
export const DATE_FORMAT_LONG = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
};
export const DATE_FORMAT_SHORT = {
    month: 'long',
    day: 'numeric',
};
export const WITH_DEBUG = IS_DEVELOPMENT;
