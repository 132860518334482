import { checkoutSettingsModel } from '../../../model';
import { showCheckoutModalV2, showCheckoutModalV3 } from './lib';
export const showCheckoutModal = async (params, checkoutVersion) => {
    if (checkoutVersion) {
        switch (checkoutVersion) {
            case 'v2': {
                return showCheckoutModalV2(params);
            }
            case 'v3': {
                return showCheckoutModalV3(params);
            }
        }
    }
    const result = await checkoutSettingsModel.getCheckoutSettingsFx({ uuid: params.paymentId || '' });
    if (result.version === 'v3') {
        return showCheckoutModalV3(params);
    }
    showCheckoutModalV2(params);
};
