import apiCommon from '@common/utils/ApiCommon';
import waitForResult from '@common/helpers/waitForResult';
import { showSubscriptionRecoveryErrorModal, showSubscriptionRecoverySuccessModal, } from '@common/entities/subscriptions/lib/modals';
import { applyOffer, getDataPulling } from '@common/components/Root/actions';
import { $inactiveSubscriptionRenewStatus } from './status';
import { $inactiveSubscriptionOffers } from './offer';
import { showCheckoutModal } from '@common/entities/checkout';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
export const renewSubscription = async () => {
    if ($inactiveSubscriptionRenewStatus.getState() === 'not_restorable') {
        const correlationId = $inactiveSubscriptionOffers.getState()[0]?.correlationId;
        if (!correlationId) {
            return;
        }
        await applyOffer({
            correlationId,
            button: {
                type: 'purchase',
            },
        });
        return;
    }
    const firstSubscription = SubscriptionsModel.$subscriptions.getState()?.[0];
    if (!firstSubscription) {
        return;
    }
    waitForResult(apiCommon.inactiveSubscriptionRenew, {
        params: [firstSubscription.own ? undefined : { vk_subscription_id: firstSubscription.id }],
        handleResponse: async (response) => {
            const renewResult = response.data;
            if (renewResult.successful) {
                const { data } = renewResult;
                switch (data.status) {
                    case 'process': {
                        waitForResult(apiCommon.getSubscriptionStatus, {
                            params: [],
                            handleResponse: async (response) => {
                                if (response.data.status_subscription_const === 'ACTIVE') {
                                    await getDataPulling()
                                        .then(() => {
                                        showSubscriptionRecoverySuccessModal();
                                    })
                                        .catch((errors) => {
                                        showSubscriptionRecoveryErrorModal(errors);
                                    });
                                    return false;
                                }
                                return true;
                            },
                            handleError: () => {
                                return true;
                            },
                        });
                        return false;
                    }
                    case 'need_direct_payment': {
                        if (data.order.payable) {
                            await showCheckoutModal({
                                paymentId: data.order.uuid,
                                type: 'offer',
                                paymentType: 'subscription_purchase',
                                paymentTypeParams: {},
                            });
                        }
                        else {
                            await getDataPulling()
                                .then(() => {
                                showSubscriptionRecoverySuccessModal();
                            })
                                .catch((errors) => {
                                showSubscriptionRecoveryErrorModal(errors);
                            });
                        }
                        return false;
                    }
                    case 'fail': {
                        showSubscriptionRecoveryErrorModal();
                        return false;
                    }
                    case 'unavailable': {
                        return false;
                    }
                }
            }
            return true;
        },
        handleError: () => {
            return false;
        },
    });
};
