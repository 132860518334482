import { attach, createEffect, createEvent, createStore, restore, sample } from 'effector';
import { createGate } from 'effector-react';
import { AuthStateModel } from '@common/entities/auth-state';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { AnalyticServiceApi } from '@common/entities/analytic-service';
import { StudentModel } from '@common/entities/student';
import { ProfileTemplateApi } from './api';
import { NOTIFICATIONS_ROUTE, PROFILE_ROUTE, TARIFFS_ROUTE } from '@website/shared/lib/profile-routes';
import { AbTestLkUntieCardV2Model } from '@common/features/ab-test-lk-untie-card-v2/model';
import { pending } from 'patronum';
const ROUTES_WHERE_DONT_RESET_IS_FIRST_RENDER_STORE = [PROFILE_ROUTE, TARIFFS_ROUTE, NOTIFICATIONS_ROUTE];
const { initProfileTemplateFx, $offers, pullingSubscriptionDataFx, pullingStudentStatusDataFx, getOffersFx } = ProfileTemplateApi;
const { callAnalyticVisitFx } = AnalyticServiceApi;
const { $authState } = AuthStateModel;
const Gate = createGate({});
const $longPullingConnections = createStore([]);
const closeLongPullingConnectionFx = createEffect((connections) => {
    connections.forEach((connection) => {
        connection();
    });
});
const attachedCloseLongPullingConnectionFx = attach({
    effect: closeLongPullingConnectionFx,
    source: $longPullingConnections,
    mapParams: (_, states) => states,
});
$longPullingConnections.reset(attachedCloseLongPullingConnectionFx.doneData);
const setFalseIsFirstRender = createEvent();
const resetIsFirstRender = createEvent();
const getCurrentPath = createEvent();
const $isFirstRender = createStore(true).reset(resetIsFirstRender);
const navigationBarLoading = pending({
    effects: [getOffersFx, AbTestLkUntieCardV2Model.getVariantsLkUntieCardForABTestsV2Fx],
});
$isFirstRender.on(setFalseIsFirstRender, () => false);
sample({
    clock: [Gate.open, $authState],
    source: {
        authorized: $authState.map((state) => state === 'authorized'),
        isProfilePage: Gate.status,
    },
    filter: ({ authorized, isProfilePage }) => {
        return isProfilePage && authorized;
    },
    target: [callAnalyticVisitFx],
});
sample({
    clock: [Gate.open, $authState],
    source: {
        authorized: $authState.map((state) => state === 'authorized'),
        isProfilePage: Gate.status,
        isFirstRender: $isFirstRender,
    },
    filter: ({ authorized, isProfilePage, isFirstRender }) => {
        return isProfilePage && isFirstRender && authorized;
    },
    target: [initProfileTemplateFx, setFalseIsFirstRender],
});
sample({
    clock: [SubscriptionsModel.$status, Gate.open],
    source: {
        isFormedSubscriptionsStatus: SubscriptionsModel.$status.map((state) => (state ? state === 'FORMED' : false)),
        isProfilePage: Gate.status,
    },
    filter: ({ isFormedSubscriptionsStatus, isProfilePage }) => {
        return isProfilePage && isFormedSubscriptionsStatus;
    },
    target: [pullingSubscriptionDataFx],
});
sample({
    clock: [StudentModel.$status, Gate.open],
    source: {
        isStatusWait: StudentModel.$status.map((state) => (state ? state === 'WAIT' : false)),
        isProfilePage: Gate.status,
    },
    filter: ({ isStatusWait, isProfilePage }) => {
        return isProfilePage && isStatusWait;
    },
    target: [pullingStudentStatusDataFx],
});
sample({
    clock: [pullingSubscriptionDataFx.doneData, pullingStudentStatusDataFx.doneData],
    source: $longPullingConnections,
    fn: (state, payload) => {
        return [...state, payload];
    },
    target: [$longPullingConnections],
});
sample({
    clock: [Gate.close],
    target: [attachedCloseLongPullingConnectionFx],
});
sample({
    clock: [Gate.open],
    source: {
        variantLkUntieCardForABTestsV2: AbTestLkUntieCardV2Model.$variantLkUntieCardForABTestsV2,
        pending: AbTestLkUntieCardV2Model.$pending,
    },
    filter: ({ variantLkUntieCardForABTestsV2, pending }) => !variantLkUntieCardForABTestsV2 && !pending,
    target: [AbTestLkUntieCardV2Model.getVariantsLkUntieCardForABTestsV2Fx],
});
sample({
    clock: getCurrentPath,
    source: {
        path: restore(getCurrentPath, ''),
        isFirstRender: $isFirstRender,
    },
    filter: ({ path, isFirstRender }) => {
        return !ROUTES_WHERE_DONT_RESET_IS_FIRST_RENDER_STORE.includes(path) && !isFirstRender;
    },
    target: [resetIsFirstRender],
});
export const ProfileTemplateModel = {
    Gate,
    $offers,
    getCurrentPath,
    navigationBarLoading,
};
