import React from 'react';
import { ButtonGroup } from '@vkontakte/vkui';
import { createAnalyticClickAttribute } from '@analytics';
import { modalManagerModel } from '@common/services/modal-manager';
import { intl } from '@common/shared/lib/intl';
import Notebook from '@common/components/icons/Notebook';
import ModalButton from '@common/components/modals/ModalButton';
import messages from './messages';
import { analyticsDictionary } from './lib/analyticsDictionary';
import { showSubscriptionCancelReasonModal } from '@common/entities/subscriptions/lib/modals';
const closeModal = modalManagerModel.closeModal;
const Actions = (props) => {
    const { subscriptionId } = props;
    const confirmHandler = () => {
        showSubscriptionCancelReasonModal({ subscriptionId });
    };
    return (<ButtonGroup stretched={true} mode={'vertical'}>
      <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalSubscriptionCancelConfirmButtonConfirmClick)} mode='secondary' onClick={confirmHandler}>
        {intl.formatMessage(messages.confirmButtonText)}
      </ModalButton>
      <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalSubscriptionCancelConfirmButtonCancelClick)} mode='primary' onClick={() => closeModal()}>
        {intl.formatMessage(messages.closeModalButtonText)}
      </ModalButton>
    </ButtonGroup>);
};
const payloadToModalManager = (payload) => {
    return {
        analyticShowAttribute: 'modal_subscription_cancel_confirm',
        icon: <Notebook />,
        header: intl.formatMessage(messages.header),
        subheader: intl.formatMessage(messages.subheader),
        actions: <Actions {...payload}/>,
    };
};
export const showSubscriptionCancelConfirmModal = (payload) => modalManagerModel.showModal(payloadToModalManager(payload));
