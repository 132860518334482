import React from 'react';
import dayjs from 'dayjs';
import { analyticsDictionary } from '@analytics';
import { intl } from '@common/shared/lib/intl';
import { modalManagerTools } from '@common/services/modal-manager';
import { SubscriptionsModel } from '../../../../../../model';
import messages from '../../../messages';
import { SuccessCancelGroupSubscriptionActions } from './ui';
const description = ({ subscription }) => {
    const subscriptionEndTime = dayjs(subscription?.subscription_end_time).format('DD.MM.YYYY');
    switch (true) {
        case subscription?.status === 'suspended': {
            return intl.formatMessage(messages.successSuspendedDescription);
        }
        default: {
            return intl.formatMessage(messages.successDefaultDescription, { subscriptionEndTime });
        }
    }
};
const actions = ({ subscription }) => {
    const isApplyOffer = subscription?.features?.group?.is_group_subscription &&
        !SubscriptionsModel.$subscriptionsQueueListWithBuffer.getState()?.length;
    return isApplyOffer ? <SuccessCancelGroupSubscriptionActions /> : undefined;
};
export const showCancelReasonSuccessModal = (payload) => modalManagerTools.modalTemplates.showMessageModal({
    analyticShowAttribute: analyticsDictionary.modalSubscriptionCancelSuccess,
    mode: 'success',
    header: intl.formatMessage(messages.successTitle),
    subheader: description(payload),
    actions: actions(payload),
});
