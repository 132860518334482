import { createEvent, createStore } from 'effector';
import { base64ToUtf8 } from '@common/utils/helpers/dataTransformation';
import { userEffects } from './effects';
const DEFAULT_STATE = {
    vkId: null,
    name: null,
    avatar: null,
    phone: null,
    paused_recurrents: null,
    pause_available: null,
};
const $userData = createStore({ ...DEFAULT_STATE });
const setVkIdByToken = createEvent();
$userData.on(setVkIdByToken, (state, payload) => {
    const jsParseToken = JSON.parse(base64ToUtf8(payload.split('.')[1]));
    const vkIdString = jsParseToken.sub;
    const vkId = Number(vkIdString);
    return { ...state, vkId };
});
$userData.on(userEffects.getProfileInfoFx.doneData, (state, payload) => {
    const { vkUserId, ...value } = payload;
    return { ...state, ...value, vkId: vkUserId };
});
$userData.on(userEffects.getPausedRecurrentsStateFx.doneData, (state, payload) => ({ ...state, ...payload }));
export const userDataModel = { $userData, setVkIdByToken };
