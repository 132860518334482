import React, { useState } from 'react';
import { useUnit } from 'effector-react';
import { ButtonGroup } from '@vkontakte/vkui';
import { Icon56ErrorOutline } from '@vkontakte/icons';
import { createAnalyticClickAttribute } from '@analytics';
import { modalManagerModel, modalManagerTools, payloadTemplates } from '@common/services/modal-manager';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { getDataPulling } from '@common/components/Root/actions';
import ModalButton from '@common/components/modals/ModalButton';
import { api } from '../../api';
import { analyticsDictionary } from '../analytics-dictionary';
import { LeaveSuccessModalActions } from './ui';
const { showMessageModal } = modalManagerTools.modalTemplates;
const Actions = () => {
    const [loading, setLoading] = useState(false);
    const $subscriptionsQueueListWithBuffer = useUnit(SubscriptionsModel.$subscriptionsQueueListWithBuffer);
    const onClick = async () => {
        setLoading(true);
        try {
            const { data } = await api.leaveMember();
            if (!data.successful) {
                throw new Error();
            }
            await getDataPulling({ all: true });
            const isApplyOffer = !$subscriptionsQueueListWithBuffer.length;
            const actions = isApplyOffer ? <LeaveSuccessModalActions /> : undefined;
            showMessageModal({
                analyticShowAttribute: analyticsDictionary.modalConfirmLeaveSuccessShow,
                mode: 'success',
                header: 'Вы вышли из совместной подписки',
                subheader: 'Теперь вы не сможете пользоваться её преимуществами. Подключите подписку на VK Музыку, чтобы и дальше слушать музыку, подкасты и аудиокниги без ограничений.',
                actions: actions,
            });
        }
        catch {
            modalManagerModel.showModal({
                analyticShowAttribute: '',
                ...payloadTemplates.alertPayloadTemplate,
            });
        }
        finally {
            setLoading(false);
        }
    };
    return (<ButtonGroup mode='vertical' gap='m' stretched={true}>
      <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalConfirmLeaveButtonConfirmClick)} size={'l'} stretched={true} loading={loading} disabled={loading} onClick={() => void onClick()}>
        Да, выйти
      </ModalButton>
      <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalConfirmLeaveButtonCancelClick)} size={'l'} stretched={true} mode={'secondary'} disabled={loading} onClick={() => modalManagerModel.closeModal()}>
        Отмена
      </ModalButton>
    </ButtonGroup>);
};
const payloadToManagerModal = () => ({
    analyticShowAttribute: analyticsDictionary.modalConfirmLeaveShow,
    icon: <Icon56ErrorOutline style={{ color: '#E64646' }}/>,
    header: 'Вы уверены, что хотите выйти из совместной подписки?',
    subheader: 'Подключиться к совместной подписке можно только по приглашению администратора',
    actions: <Actions />,
});
export const showConfirmLeaveModal = () => modalManagerModel.showModal(payloadToManagerModal());
