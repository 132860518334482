import { useMemo } from 'react';
import { useUnit } from 'effector-react';
import { createAnalyticAttrs } from '@common/utils/Analytics';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { AuthStateModel } from '@common/entities/auth-state';
import Typograf from '@common/components/common/Typograf';
import { ApplyOfferModel } from '../../model';
import { ApplyOfferButtonCommon } from '@website/shared/ui/molecules';
import { applyOfferWebsiteFactory } from '../../lib/apply-offer';
import { goToVkMusic } from '@website/shared/lib/go-to-vk-music';
const ApplyOfferButton = (props) => {
    const { type = 'offer', className, offer, offersState, skipCheckSubscription = false, ...restProps } = props;
    const authState = useUnit(AuthStateModel.$authState);
    const correlationIdInProgress = useUnit(ApplyOfferModel.$correlationIdInProgress);
    const hasActiveSubscriptionByStatus = useUnit(SubscriptionsModel.$hasActiveSubscriptionStatus);
    const hasActiveSubscriptionByList = useUnit(SubscriptionsModel.$hasActiveSubscriptionByList);
    const subscriptionStatusAreLoading = useUnit(SubscriptionsModel.getSubscriptionStatusFx.pending);
    const subscriptionsListAreLoading = useUnit(SubscriptionsModel.getSubscriptionsFx.pending);
    const widget = offer ? offer.widgets[type] : null;
    const button = widget ? widget.buttons[0] : null;
    const applyOffer = applyOfferWebsiteFactory({ skipCheckSubscription });
    const hasActiveSubscriptions = (!subscriptionStatusAreLoading && hasActiveSubscriptionByStatus) ||
        (!subscriptionsListAreLoading && hasActiveSubscriptionByList);
    const offerIsExist = offersState === 'success' || offersState === 'error';
    const offerLoading = offersState === 'loading';
    const { isButtonLoading = false, isButtonDisabled = false, text = '', additionalProps = {}, } = useMemo(() => {
        let textFromOffer = '';
        let additionalPropsFromOffer = {};
        const textGoToLk = 'Перейти в музыку';
        if (button?.metrics) {
            textFromOffer = button?.name ?? '';
            button?.metrics.forEach((metric) => {
                additionalPropsFromOffer = {
                    key: metric.value,
                    ...additionalPropsFromOffer,
                    ...createAnalyticAttrs(metric.value, [metric.type]),
                };
            });
        }
        switch (authState) {
            case 'idle':
                return { isButtonLoading: true, isButtonDisabled: true };
            case 'authorized':
                if (!skipCheckSubscription) {
                    if (hasActiveSubscriptions) {
                        return {
                            text: textGoToLk,
                            additionalProps: {
                                key: 'authorized',
                            },
                        };
                    }
                    else if (subscriptionStatusAreLoading || subscriptionsListAreLoading) {
                        return {
                            isButtonDisabled: true,
                            isButtonLoading: true,
                        };
                    }
                }
                if (!offerIsExist || offerLoading) {
                    return {
                        isButtonDisabled: true,
                        isButtonLoading: true,
                    };
                }
                return {
                    text: textFromOffer,
                    additionalProps: additionalPropsFromOffer,
                    isButtonDisabled: Boolean(correlationIdInProgress),
                    isButtonLoading: offer?.correlationId === correlationIdInProgress,
                };
            case 'unauthorized':
                if (!offerIsExist || offerLoading) {
                    return {
                        isButtonDisabled: true,
                        isButtonLoading: true,
                    };
                }
                return {
                    text: textFromOffer,
                    additionalProps: additionalPropsFromOffer,
                    isButtonLoading: offerLoading,
                    isButtonDisabled: offerLoading,
                };
        }
    }, [
        skipCheckSubscription,
        authState,
        correlationIdInProgress,
        hasActiveSubscriptions,
        subscriptionStatusAreLoading,
        subscriptionsListAreLoading,
        offer,
        button,
        offerLoading,
        offerIsExist,
    ]);
    const applyOfferClick = async () => {
        if (!skipCheckSubscription && hasActiveSubscriptions) {
            goToVkMusic();
            return;
        }
        if (offer && button) {
            await applyOffer({
                correlationId: offer.correlationId,
                button,
                offerName: widget?.badge?.title ?? '',
                subscriptionType: offer.with.subscriptionType,
            });
        }
    };
    return (<ApplyOfferButtonCommon className={className} onClick={applyOfferClick} disabled={isButtonDisabled} loading={isButtonLoading} {...restProps} {...additionalProps} key={additionalProps.key}>
      <Typograf>{text}</Typograf>
    </ApplyOfferButtonCommon>);
};
export default ApplyOfferButton;
