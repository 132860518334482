import { analyticsDictionary } from '@analytics';
import { intl } from '@common/shared/lib/intl';
import { messages } from '@common/entities/checkout/messages';
export const commonShowSuccessfulPaymentModalParams = ({ type = 'offer', paymentType, paymentTypeParams, }) => {
    const subscriptionEndTime = paymentTypeParams?.subscriptionEndTime || '';
    const description = paymentType === 'onetime_purchase_subscription_cis'
        ? intl.formatMessage(messages.successfulPaymentModalOnetimeDescription, { subscriptionEndTime })
        : intl.formatMessage(messages.successfulPaymentModalDescription);
    return {
        modalElementKey: analyticsDictionary.modalCheckoutSuccessShow(type),
        mode: 'success',
        title: intl.formatMessage(messages.successfulPaymentModalTitle),
        description,
        type: type ?? 'offer',
        platform: 'vkcom',
    };
};
