import { getFeatureFlags as getFeatureFlagsApi } from './api';
const featureFlagsDefault = {
    try_open_app_instead_of_auth: false,
    process_promocodes_via_service: false,
    enable_offer_shelf_redesign_1124: false,
    enable_miniapp_profile_redesing_1224: false,
};
let featureFlagsValues = null;
export const getFeatureFlags = async () => {
    let featureFlags;
    try {
        const { data } = await getFeatureFlagsApi();
        featureFlags = Object.assign({}, featureFlagsDefault, data);
    }
    catch {
        featureFlags = featureFlagsDefault;
    }
    return featureFlags;
};
export const getFeatureFlag = async (featureFlag, refresh = false) => {
    if (refresh || !featureFlagsValues) {
        featureFlagsValues = await getFeatureFlags();
    }
    return featureFlagsValues[featureFlag] ?? false;
};
