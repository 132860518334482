import apiCommon from '@common/utils/ApiCommon';
import { getDataPulling } from '@common/components/Root/actions';
import { formatDate } from '@common/shared/lib/intl';
import { getSubscriptionById, showSubscriptionPauseRecurrentsErrorModal, showSubscriptionPauseRecurrentsSuccessModal, } from '@common/entities/subscriptions/lib';
export const pauseRecurrent = async (params) => {
    const { subscriptionId } = params;
    try {
        const response = await apiCommon.pauseSubscriptionRecurrent().then((res) => res.data);
        await getDataPulling({ all: true });
        if (!response.successful) {
            showSubscriptionPauseRecurrentsErrorModal(response.errors);
            return;
        }
        const { new_recurrent_date } = response.data;
        const newRecurrentDateToRender = formatDate(new_recurrent_date) || '';
        const subscriptionEndTime = getSubscriptionById(subscriptionId).getState()?.subscription_end_time || '';
        const subscriptionEndTimeToRender = formatDate(subscriptionEndTime) || '';
        showSubscriptionPauseRecurrentsSuccessModal({
            newRecurrentDate: newRecurrentDateToRender,
            subscriptionEndTime: subscriptionEndTimeToRender,
        });
    }
    catch (e) {
        showSubscriptionPauseRecurrentsErrorModal();
    }
};
