import icon80ForFour3XWebp from './icon-80-for-four@3x.webp';
import icon80ForFour3XPng from './icon-80-for-four@3x.png';
import icon80ForFour2XWebp from './icon-80-for-four@2x.webp';
import icon80ForFour2XPng from './icon-80-for-four@2x.png';
import icon80ForFour1XWebp from './icon-80-for-four@1x.webp';
import icon80ForFour1XPng from './icon-80-for-four@1x.png';
export const icon80ForFourImage = {
    src: icon80ForFour1XPng.src,
    width: 80,
    height: 80,
    sources: [
        { width: 80, height: 80, srcSet: icon80ForFour3XWebp.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 80, height: 80, srcSet: icon80ForFour3XPng.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 80, height: 80, srcSet: icon80ForFour2XWebp.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 80, height: 80, srcSet: icon80ForFour2XPng.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 80, height: 80, srcSet: icon80ForFour1XWebp.src },
        { width: 80, height: 80, srcSet: icon80ForFour1XPng.src },
    ],
};
