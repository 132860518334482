import { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { AuthStateModel } from '@common/entities/auth-state';
import { getRedirectAuthState, VK_ID_PAYLOAD_FIELD_NAME, VK_ID_STATE_FIELD_NAME } from '@common/entities/auth-vkid';
import { StrategyContext, StrategyFactory } from './lib/strategy';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { modifyNextSearchParams } from '@common/shared/lib/modify-next-search-params';
export const useApplyOfferObserver = () => {
    const authState = useUnit(AuthStateModel.$authState);
    const subscriptionsState = useUnit(SubscriptionsModel.$subscriptionsState);
    useEffect(() => {
        if (authState !== 'authorized') {
            return;
        }
        if (subscriptionsState !== 'success') {
            return;
        }
        const redirectState = getRedirectAuthState();
        if (!redirectState) {
            modifyNextSearchParams({ paramsToRemove: [VK_ID_PAYLOAD_FIELD_NAME, VK_ID_STATE_FIELD_NAME] });
            return;
        }
        const strategy = StrategyFactory.getStrategy(redirectState.flow);
        if (!strategy) {
            return;
        }
        const context = new StrategyContext(strategy);
        context.doStrategy(redirectState);
        modifyNextSearchParams({ paramsToRemove: [VK_ID_PAYLOAD_FIELD_NAME, VK_ID_STATE_FIELD_NAME] });
    }, [authState, subscriptionsState]);
};
