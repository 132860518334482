import { groupSubscriptionFlowModel } from '@common/features/group-subscription-flow';
import { getOffers } from '../actions/getOffers';
import { KEYWORDS_WEBSITE_ALL } from '../consts';
export class GroupSubscriptionStrategy {
    async do(state) {
        const redirectState = state;
        if (!redirectState) {
            return;
        }
        if (redirectState.flow !== 'group_subscription')
            return;
        const { params: { offerName, correlationId }, } = redirectState;
        const offers = await getOffers({ keywords: KEYWORDS_WEBSITE_ALL });
        const isOfferAvailable = offers.success && offers.payload.find((item) => item.correlationId === correlationId);
        if (!isOfferAvailable) {
            return;
        }
        void groupSubscriptionFlowModel.start({ correlationId, offerName });
    }
}
