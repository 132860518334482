import { applyOfferWebsiteFactory } from '@website/features/apply-offer';
import { getOffers } from '../actions/getOffers';
import { KEYWORDS_WEBSITE_ALL } from '../consts';
export class ApplyOfferStrategy {
    async do(state) {
        const redirectState = state;
        if (!redirectState) {
            return;
        }
        const { params, flow } = redirectState;
        if (flow !== 'applyOffer')
            return;
        const button = { type: 'purchase' };
        const applyOffer = applyOfferWebsiteFactory({ skipCheckSubscription: true });
        const offers = await getOffers({ keywords: KEYWORDS_WEBSITE_ALL });
        const isOfferAvailable = offers.success && offers.payload.find((item) => item.correlationId === params.correlationId);
        if (!isOfferAvailable) {
            return;
        }
        void applyOffer({
            ...params,
            button,
        });
    }
}
