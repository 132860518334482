import icon80Education3XWebp from './icon-80-education@3x.webp';
import icon80Education3XPng from './icon-80-education@3x.png';
import icon80Education2XWebp from './icon-80-education@2x.webp';
import icon80Education2XPng from './icon-80-education@2x.png';
import icon80Education1XWebp from './icon-80-education@1x.webp';
import icon80Education1XPng from './icon-80-education@1x.png';
export const icon80EducationImage = {
    src: icon80Education1XPng.src,
    width: 80,
    height: 80,
    sources: [
        { width: 80, height: 80, srcSet: icon80Education3XWebp.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 80, height: 80, srcSet: icon80Education3XPng.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 80, height: 80, srcSet: icon80Education2XWebp.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 80, height: 80, srcSet: icon80Education2XPng.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 80, height: 80, srcSet: icon80Education1XWebp.src },
        { width: 80, height: 80, srcSet: icon80Education1XPng.src },
    ],
};
