import { Connect } from '@vkontakte/superappkit';
import { base64ToUtf8, utf8ToBase64 } from '@common/utils/helpers/dataTransformation';
import { validate, validateData } from '@common/shared/lib/validate-data/validateData';
import isEmpty from 'lodash/isEmpty';
import RedirectAuthStateSchema from '../lib/schemas/RedirectAuthStateSchema.json';
import { getIsRelatedAppInstalled } from '@apps/common/shared/lib/get-is-related-app-installed';
import { getFeatureFlag } from '@common/shared/lib/feature-flags';
import { PRODUCTION_MINIAPP_URL, PRODUCTION_MINIAPP_URL_CHECKOUT, PRODUCTION_MINIAPP_URL_STUDENTS, } from '@common/shared/lib/constants';
import { errorLog } from '@common/shared/lib/utils';
export const VK_ID_PAYLOAD_FIELD_NAME = 'payload';
export const VK_ID_STATE_FIELD_NAME = 'state';
export const redirectAuth = () => {
    const schema = {
        type: 'object',
        properties: {
            auth: { enum: [1] },
        },
        required: ['token', 'uuid'],
    };
    const { payloadFromParams, payloadFromFragment } = getPayloadForRedirectAuth();
    const payload = payloadFromParams || payloadFromFragment;
    if (!payload) {
        return Promise.reject();
    }
    validateData(schema, payload, () => {
        throw new Error();
    }, 'redirectAuth');
    return Promise.resolve({
        token: payload.token,
        uuid: payload.uuid,
    });
};
export const redirectToVKID = (state) => {
    const decodeState = utf8ToBase64(JSON.stringify(state || {}));
    Connect.redirectAuth({
        url: window.location.href,
        state: decodeState,
    });
};
export const redirectToVKIDWithCheckDeepLink = async (state) => {
    const isRelatedAppInstalled = await getIsRelatedAppInstalled();
    if (isRelatedAppInstalled) {
        const tryOpenAppInsteadOfAuthEnabled = await getFeatureFlag('try_open_app_instead_of_auth');
        if (tryOpenAppInsteadOfAuthEnabled) {
            if (state) {
                const { flow, params } = state;
                switch (flow) {
                    case 'student': {
                        window.location.href = PRODUCTION_MINIAPP_URL_STUDENTS;
                        break;
                    }
                    case 'applyOffer': {
                        if (params.subscriptionType) {
                            window.location.href = `${PRODUCTION_MINIAPP_URL_CHECKOUT}=${params.subscriptionType}`;
                            return;
                        }
                        window.location.href = PRODUCTION_MINIAPP_URL_CHECKOUT;
                        break;
                    }
                    default: {
                        window.location.href = PRODUCTION_MINIAPP_URL;
                    }
                }
                return;
            }
            window.location.href = PRODUCTION_MINIAPP_URL;
            return;
        }
    }
    redirectToVKID(state);
};
export const getPayloadForRedirectAuth = () => {
    let payloadFromParams = null;
    let payloadFromFragment = null;
    const location = document.location;
    const schema = {
        type: 'object',
        properties: {
            auth: { enum: [1] },
        },
        required: ['token', 'uuid'],
    };
    try {
        const payloadFromParamsCandidate = JSON.parse(new URLSearchParams(location.search.substring(1)).get(VK_ID_PAYLOAD_FIELD_NAME) || 'null');
        if (validate(schema, payloadFromParamsCandidate).valid) {
            payloadFromParams = payloadFromParamsCandidate;
        }
    }
    catch { }
    try {
        location.hash.split(/[?&]/).some((hashPiece) => {
            const payloadFromFragmentCandidate = JSON.parse(new URLSearchParams(hashPiece).get(VK_ID_PAYLOAD_FIELD_NAME) || 'null');
            if (validate(schema, payloadFromFragmentCandidate).valid) {
                payloadFromFragment = payloadFromFragmentCandidate;
                return true;
            }
            return false;
        });
    }
    catch { }
    const hasPayload = Boolean(payloadFromParams || payloadFromFragment);
    return {
        payloadFromParams,
        payloadFromFragment,
        hasPayload,
    };
};
export const getRedirectAuthState = () => {
    try {
        const urlParams = new URLSearchParams(document.location.search);
        const redirectStateEncrypt = urlParams.get(VK_ID_STATE_FIELD_NAME);
        if (!redirectStateEncrypt) {
            return null;
        }
        const redirectState = JSON.parse(base64ToUtf8(redirectStateEncrypt));
        if (isEmpty(redirectState)) {
            return null;
        }
        validateData(RedirectAuthStateSchema, redirectState, () => {
            throw new Error();
        }, 'getRedirectAuthState');
        return redirectState;
    }
    catch (e) {
        errorLog(e);
        return null;
    }
};
