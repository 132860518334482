import React from 'react';
import { analyticsDictionary } from '@analytics';
import { modalManagerModel, modalManagerTools } from '@common/services/modal-manager';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { showCheckoutModal } from '@common/entities/checkout';
import { applyOffer, getDataPulling } from '@common/components/Root/actions';
import { Children, Footer, Header } from './ui';
export const showSubscriptionDetails = (params) => {
    const { subscriptionId } = params;
    const closeModal = () => {
        modalManagerModel.closeModal();
    };
    const showAlertModal = (params) => {
        const { modalElementKey, mode, title, description } = params;
        modalManagerTools.modalTemplates.showMessageModal({
            analyticShowAttribute: modalElementKey ?? '',
            mode,
            header: title,
            subheader: description,
        });
    };
    const updateSubscriptionsWithoutReload = () => SubscriptionsModel.getSubscriptionsAndSubscriptionsStatusFx({ state: 'updating' });
    const renewInactiveSubscription = async ({ uuid, paymentType, paymentTypeParams }) => {
        await showCheckoutModal({ paymentId: uuid, type: 'offer', paymentType, paymentTypeParams });
    };
    const applyPublicOffer = (args) => applyOffer(args).finally(() => {
        setTimeout(() => {
            closeModal();
        }, 100);
    });
    const updateData = async () => {
        await getDataPulling();
    };
    const contextProps = {
        subscriptionId,
        showAlertModal,
        closeModal,
        updateSubscriptionsWithoutReload,
        renewInactiveSubscription,
        applyPublicOffer,
        updateData,
    };
    modalManagerModel.showModalPageWithFixedFooter({
        analyticShowAttribute: analyticsDictionary.modalSubscriptionManagementShow,
        header: <Header />,
        children: (<Children subscriptionId={subscriptionId} contextProps={contextProps}/>),
        footer: (<Footer subscriptionId={subscriptionId} contextProps={contextProps}/>),
    });
};
