import React from 'react';
const DEFAULT_WIDTH = 125;
const WIDTH_HEIGHT_RATIO = 5;
const VKMusicLogo = (props) => {
    const { width = DEFAULT_WIDTH, isDark, className } = props;
    const height = width / WIDTH_HEIGHT_RATIO;
    return (<svg style={{
            width,
            height,
            display: 'block',
        }} width={width} height={height} viewBox='0 0 124 25' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
      <path d='M39.6663 18.4845L44.1296 6.07349L41.924 6.07349L38.3464 16.3016L37.9991 16.3016L34.5431 6.07349L32.3027 6.07349L36.6271 18.4845L39.6663 18.4845Z' fill={isDark ? '#000' : '#fff'}/>
      <path d='M49.9158 12.0589L55.1085 6.07349L52.5382 6.07349L48.1965 11.1259L47.6928 11.1259L47.6928 6.07349L45.6088 6.07349L45.6088 18.4845L47.6928 18.4845L47.6928 13.168L48.2138 13.168L52.7987 18.4845L55.5427 18.4845L49.9158 12.0589Z' fill={isDark ? '#000' : '#fff'}/>
      <path d='M62.5315 18.4845L62.5315 8.89017L62.8094 8.89017L66.2307 18.4845L68.1758 18.4845L71.6665 8.89017L71.9444 8.89017L71.9444 18.4845L73.9764 18.4845L73.9764 6.07349L70.7808 6.07349L67.3769 15.4566L67.099 15.4566L63.7646 6.07349L60.4996 6.07349L60.4996 18.4845L62.5315 18.4845Z' fill={isDark ? '#000' : '#fff'}/>
      <path d='M80.5199 15.9319L80.242 15.9319L77.5849 9.33027L75.4487 9.33027L79.4605 18.9774L79.3563 19.2591C79.0958 19.9985 78.7832 20.2449 78.1233 20.2449L76.4387 20.2449L76.6123 22.0934L77.9843 22.0934C79.6515 22.0934 80.5546 21.3364 81.1625 19.6288L84.8616 9.33027L82.8297 9.33027L80.5199 15.9319Z' fill={isDark ? '#000' : '#fff'}/>
      <path d='M91.1439 13.8194C92.0643 13.5729 92.7416 12.8159 92.7416 11.7773C92.7416 10.2457 91.3002 9.15423 89.303 9.15423C87.5142 9.15423 86.0032 9.99924 85.4475 11.7421L87.2884 12.0589C87.6531 11.3372 88.3651 10.8794 89.2335 10.8794C90.0671 10.8794 90.7444 11.302 90.7444 11.9885C90.7444 12.6047 90.1887 13.0096 89.1119 13.0096L87.9831 13.0096L87.9831 14.77L89.1119 14.77C90.4665 14.77 90.8833 15.2277 90.8833 15.8086C90.8833 16.5128 90.2581 16.9353 89.1119 16.9353C88.0699 16.9353 87.4621 16.5832 87.0626 15.7734L85.2217 16.0903C85.6559 17.6219 87.1321 18.6605 89.1293 18.6605C91.4217 18.6605 92.8805 17.4634 92.8805 15.9671C92.8805 14.8228 92.0643 14.101 91.1439 13.8194Z' fill={isDark ? '#000' : '#fff'}/>
      <path d='M98.1924 18.4845C100.346 18.4845 101.874 17.129 101.874 15.2101C101.874 13.2912 100.363 11.9357 98.1924 11.9357L96.7857 11.9357L96.7857 9.33027L94.7885 9.33027L94.7885 18.4845L98.1924 18.4845ZM104.948 18.4845L104.948 9.33027L102.951 9.33027L102.951 18.4845L104.948 18.4845ZM96.7857 13.6961L98.1924 13.6961C99.1823 13.6961 99.877 14.3123 99.877 15.2101C99.877 16.1079 99.1823 16.7241 98.1924 16.7241L96.7857 16.7241L96.7857 13.6961Z' fill={isDark ? '#000' : '#fff'}/>
      <path d='M115.435 18.4845L111.284 13.7313L115.088 9.33027L112.708 9.33027L109.687 12.8863L109.2 12.8863L109.2 9.33027L107.203 9.33027L107.203 18.4845L109.2 18.4845L109.2 14.8404L109.687 14.8404L112.813 18.4845L115.435 18.4845Z' fill={isDark ? '#000' : '#fff'}/>
      <path d='M119.908 9.15423C118.154 9.15423 116.608 10.0697 116.156 11.654L117.997 11.9709C118.275 11.4076 118.9 10.8794 119.803 10.8794C120.898 10.8794 121.54 11.6716 121.627 12.8687L119.682 12.8687C117.025 12.8687 115.705 14.0482 115.705 15.7382C115.705 17.4458 117.007 18.6605 118.848 18.6605C120.324 18.6605 121.314 17.7979 121.748 17.0409L122.165 18.4845L123.641 18.4845L123.641 13.344C123.641 10.6858 122.217 9.15423 119.908 9.15423ZM119.317 16.9529C118.345 16.9529 117.719 16.4424 117.719 15.6502C117.719 14.946 118.223 14.4531 119.751 14.4531L121.644 14.4531C121.644 15.9495 120.706 16.9529 119.317 16.9529Z' fill={isDark ? '#000' : '#fff'}/>
      <path d='M1.70907 1.73247C0.0510834 3.41312 0.00148258 6.08742 -1.33606e-08 11.337C4.641 11.337 7.53861 9.31729 10.0803 7.54567C12.0659 6.16165 13.8343 4.92904 16.047 4.92904C19.3404 4.92904 22.216 7.28949 23.7408 8.5411C23.9558 8.7176 24.144 8.87205 24.3027 8.99471C24.2545 5.25191 23.9956 3.14248 22.6046 1.73247C20.8954 7.97686e-07 18.1447 5.52044e-07 12.6431 7.10813e-08L11.6705 -1.39415e-08C6.16897 1.38543e-06 3.41819 1.05361e-06 1.70907 1.73247Z' fill='#0077FF'/>
      <path d='M10.9557 24.6458C11.1887 24.646 11.4269 24.646 11.6705 24.646L12.6431 24.646C12.8867 24.646 13.1249 24.646 13.3579 24.6458L10.9557 24.6458Z' fill='#0077FF'/>
      <path d='M4.64952e-05 11.3369C4.64105 11.3369 7.53865 9.3172 10.0803 7.54558C12.066 6.16157 13.8344 4.92895 16.0471 4.92896C19.3405 4.92896 22.2161 7.2894 23.7408 8.54101C23.9559 8.71751 24.144 8.87196 24.3027 8.99463C24.3137 9.85059 24.3137 10.792 24.3137 11.83L24.3137 12.8158C24.3137 18.3926 24.3137 21.1809 22.6046 22.9134C20.9711 24.5692 18.3862 24.6425 13.3577 24.6457L10.956 24.6457C5.92751 24.6425 3.34257 24.5692 1.70912 22.9134C-8.30711e-07 21.1809 -4.96319e-07 18.3926 -8.78495e-09 12.8158L7.73997e-08 11.83C9.1985e-08 11.6631 1.06354e-07 11.4988 4.64952e-05 11.3369Z' fill='#F45FFF'/>
      <path d='M18.8428 12.5634C18.8428 11.9542 19.336 11.4604 19.9369 11.4604V11.4604C20.5378 11.4604 21.031 11.9542 21.031 12.5634L21.031 17.012C21.031 17.6211 20.5378 18.1149 19.9369 18.1149V18.1149C19.336 18.1149 18.8428 17.6211 18.8428 17.012L18.8428 12.5634Z' fill='white'/>
      <path d='M14.9531 9.72913C14.9531 9.12001 15.4463 8.62622 16.0472 8.62622V8.62622C16.6482 8.62622 17.1414 9.12001 17.1414 9.72913L17.1414 19.8463C17.1414 20.4554 16.6482 20.9492 16.0472 20.9492V20.9492C15.4463 20.9492 14.9531 20.4554 14.9531 19.8463L14.9531 9.72913Z' fill='white'/>
      <path d='M11.0625 12.5634C11.0625 11.9542 11.5557 11.4604 12.1566 11.4604V11.4604C12.7575 11.4604 13.2507 11.9542 13.2507 12.5634L13.2507 17.012C13.2507 17.6211 12.7575 18.1149 12.1566 18.1149V18.1149C11.5557 18.1149 11.0625 17.6211 11.0625 17.012L11.0625 12.5634Z' fill='white'/>
      <path d='M7.17236 14.1652C7.17236 13.556 7.66557 13.0623 8.26648 13.0623V13.0623C8.86738 13.0623 9.36059 13.556 9.36059 14.1652L9.36059 15.4098C9.36059 16.0189 8.86738 16.5127 8.26648 16.5127V16.5127C7.66557 16.5127 7.17236 16.0189 7.17236 15.4098L7.17236 14.1652Z' fill='white'/>
      <path d='M3.28174 14.1652C3.28174 13.556 3.77495 13.0623 4.37585 13.0623V13.0623C4.97676 13.0623 5.46997 13.556 5.46997 14.1652L5.46997 15.4098C5.46997 16.0189 4.97676 16.5127 4.37585 16.5127V16.5127C3.77495 16.5127 3.28174 16.0189 3.28174 15.4098L3.28174 14.1652Z' fill='white'/>
    </svg>);
};
export default VKMusicLogo;
