import { KeywordsKeeper } from '@common/entities/offers';
export const getPlatformOffersKeyWord = async (isProfileAvailable = false) => {
    const [profileAvailableKeyword, profileNotAvailableKeyword] = await Promise.all([
        KeywordsKeeper.getKeywordsByDictionaryKey('profileAvailableKeyword'),
        KeywordsKeeper.getKeywordsByDictionaryKey('profileNotAvailableKeyword'),
    ]);
    if (isProfileAvailable) {
        return profileAvailableKeyword;
    }
    return profileNotAvailableKeyword;
};
