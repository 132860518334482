import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { SUBSCRIPTION_TYPE_WHICH_NEED_REDIRECT_TO_OLD_PROFILE_LIST } from '@common/entities/subscriptions/lib';
import { ROUTES } from '@website/shared/lib/routes';
const CurrentSubscriptionGuard = ({ children }) => {
    const currentSubscriptionInfo = useUnit(SubscriptionsModel.$currentSubscriptionInfo);
    const DoesntShowContent = SUBSCRIPTION_TYPE_WHICH_NEED_REDIRECT_TO_OLD_PROFILE_LIST.includes(currentSubscriptionInfo ?? '');
    const content = DoesntShowContent ? null : children;
    useEffect(() => {
        if (DoesntShowContent) {
            location.href = ROUTES.profileSpecial;
        }
    }, [DoesntShowContent]);
    return <>{content}</>;
};
export default CurrentSubscriptionGuard;
